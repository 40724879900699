"use client";
import styled from "styled-components";

import { fontUtil } from "@/styles/website/font";
import Media from "@/styles/website/Media";
export default function Custom404() {
  return (
    <ErrorPage>
      <ErrorWrapper>
        <ErrorImage />
        <ErrorText>
          존재하지 않는 페이지입니다.
          <br />
          서비스 이용에 불편을 드려 죄송합니다.
        </ErrorText>
      </ErrorWrapper>
    </ErrorPage>
  );
}
const ErrorPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${Media.desktop`
    width: 440px;
    height: 276px;
  `};
  ${Media.tablet`
    width: 366px;
    height: 234px;
  `};
  ${Media.mobile`
    width: 313px;
    height: 192px;
  `};
`;
const ErrorImage = styled.img`
  content: url("/static/website/error_bike.png");
  ${Media.desktop`
    width: 175.19px;
    margin-top: 30px;
    margin-bottom: 30px;
  `};
  ${Media.tablet`
    width: 145.99px;
    margin-top: 25px;
    margin-bottom: 25px;
  `};
  ${Media.mobile`
    width: 116.79px;
    margin-top: 20px;
    margin-bottom: 20px;
  `};
`;
const ErrorText = styled.span`
  margin-top: 30px;
  color: ${(props) => props.theme.colors.gray400};
  ${fontUtil("subtitle2")}
`;
